// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@custom-media --viewport-mobile (width <= 600px);
@custom-media --viewport-desktop (width > 600px);
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/viewport.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD,gDAAgD","sourcesContent":["@custom-media --viewport-mobile (width <= 600px);\n@custom-media --viewport-desktop (width > 600px);\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
